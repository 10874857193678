import Navbar from "./Navbar";
import Footer from "./Footer";
import segImage from './imgs/kironde.jpg';  
import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from 'react-helmet';

const Segments = () => {
    const { id } = useParams();
    const [videos, setVideos] = useState([]);

    useEffect(() => { 
        fetchVideos(); 
    }, [id]); 


    const fetchVideos = async () => {
        try{
            const response = await axios.get('https://api.campus360recreations.com/videos/'); 
            const data = response.data.filter((video) => (video.type == id)); 
            setVideos(data);   
        } catch(error) {
            console.log('an error occured'+ error); 
        }
    };
    return ( 
        <div className="segments">
            <Helmet>
                <title>Segments</title>
                <meta name="description" content="Get to know the different kinds of segments that we have at 360" />  
            </Helmet>
            <div className="seg-container "> 
                    <Navbar navlinks='show' />
                    <img src={segImage} alt="" />
                    {/* <div className="thumb">
                        <img src={segImage} alt="" />
                    </div> */}
            </div>
            <div className="container relative mx-auto mt-5 px-2 md:mt-20 text-white">
             <h1 className="text-4xl md:text-6xl font-bold">{ id }</h1>
             <div className="mt-6 grid gap-4 justifty-center md:grid-cols-3">
                    {videos.length > 0 ? (videos.map((vid) => (
                        <div className="thumb">
                            <div className="thumbnail">
                                <img src={vid.thumbnail} alt="no-image" className="rounded-xl"/>
                            </div>
                            <div className="thumb-footer mt-10 flex">
                                <Link className="links" to={`/videos/watch/${vid.id}`}><h1 className="text-2xl md:text-4xl ">{vid.title}</h1></Link>
                            </div>
                        </div>  
                    ))): (<p className="font-bold">No videos Uploaded Yet!....</p>)}
            </div> 
              
            </div>
            <Footer />
        </div>
     );
}
 
export default Segments;