const CoursesLists = ({list}) => {
    return ( 
        <div>
            <div className="grid grid-col-4">
                    <div className="flex">
                        <div className="icon">
                        <ul className="space-y-4 text-left text-gray-500 dark:text-gray-400">
                            {list && list.map((index, item) => ( 
                                    <li className="flex items-center space-x-3 rtl:space-x-reverse" key={item}>
                                        <svg className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                        </svg>
                                        <span className="text-xs md:text-sm">{index}</span>
                                    </li>  
                            ))}
                            </ul>
                        </div>
                    </div>
                </div>
        </div>
     );
}
 
export default CoursesLists;