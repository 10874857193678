import { Link } from "react-router-dom";

const SideBar = () => {
    return ( 
        <div className="side-bar">
            <ul>
                <li>
                    <Link to="/about" className={window.location.pathname === '/about' ? 'primary font-bold text-2xl': 'text-3xl'}>About</Link>
                </li> 
                <li>
                    <Link to="/terms" className={window.location.pathname === '/terms' ? 'primary font-bold text-2xl': 'text-3xl'}>Terms & Conditions</Link>
                </li> 
                <li>
                    <Link to="/privacy" className={window.location.pathname === '/privacy' ? 'primary font-bold text-2xl': 'text-3xl'}>Privacy</Link>
                </li> 
                <li>
                    <Link to="/" className={window.location.pathname === '/' ? 'primary font-bold text-2xl': 'text-3xl'}>Follow Us</Link>
                </li> 
            </ul>
        </div>
     );
}
 
export default SideBar;