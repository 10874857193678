import React, { useState, useEffect } from 'react';

const Counter = () => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        const target = 50;
        const duration = 2000; // 2 seconds
        const increment = target / (duration / 1000 * 60); // assuming 60 frames per second
        let current = 0;

        const interval = setInterval(() => {
            current += increment;
            if (current >= target) {
                current = target;
                clearInterval(interval);
            }
            setCount(Math.floor(current));
        }, 1000 / 60); // 60 times per second (60 FPS)

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    return (
        <div>
            {count}%
        </div>
    );
};

export default Counter;
