import React, { useState } from 'react';

const ReadMore = ({ text, maxLength = 200 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <p className='px-4 text-gray-500'>
        {isExpanded ? text : `${text.substring(0, maxLength)}...`}
      </p>
      <button
        onClick={toggleReadMore}
        className="button py-3 px-5 mx-auto rounded-xl md:mt-5"
      >
        {isExpanded ? 'Read Less' : 'Read More'}
      </button>
    </div>
  );
};

export default ReadMore;
