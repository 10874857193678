import Navbar from "./Navbar";
import Footer from "./Footer"; 
import {Link} from "react-router-dom";
import { useState, useEffect } from "react"; 
import axios from "axios";
import Skeleton from "./Skeleton";
import { Helmet } from 'react-helmet';

import { FaPlayCircle } from 'react-icons/fa';

import confessionsImg from "./imgs/camp/360 Confessions.jpg";
import foodhub from './imgs/camp/360 Food hub.png'; 
import mentor from './imgs/camp/360 Mentor.jpg';
import news from "./imgs/camp/360 News.jpg";
import bliss from "./imgs/camp/360 Evening Bliss.jpg";
import poll from "./imgs/camp/360 Fashion Poll.jpg";
import fitness from "./imgs/camp/360 Fitness.jpg";
import thought from "./imgs/camp/360 Food 4 Thought.jpg";
import games from "./imgs/camp/360 Gamez.jpg";
import hotseat from "./imgs/camp/360 HotSeat.jpg";
import opinion from "./imgs/camp/360 Opinion.jpg";
import tech from "./imgs/camp/360 Tech Series.jpg";
import wid from "./imgs/camp/360 WID.jpg"; 
import sports from "./imgs/camp/360 Sports.jpg"; 
import { toast, Toaster } from "react-hot-toast";

const imagePaths = [sports, confessionsImg, bliss, news, mentor, foodhub, poll, fitness, thought, games, hotseat, opinion, tech, wid];

const HomeVideos = () => {
const [isPending, setIspending] = useState(false); 
const [videos, setVideos] = useState([]);

const [setOne, setRandomItemsOne] = useState([]);
const [setTwo, setRandomItemsTwo] = useState([]);
const [setThree, setRandomItemsThree] = useState([]);

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const getRandomItems = (array, numItems) => {
    const shuffledArray = shuffleArray([...array]);
    return shuffledArray.slice(0, numItems);
  };

const fetchVideos = async () => {
    setIspending(true);
    try{
        const response = await axios.get('https://api.campus360recreations.com/videos/'); 
        setVideos(response.data);
        //console.log(response.data); 
        setRandomItemsThree(getThreeMostRecentItems(response.data));  
        setRandomItemsOne(getTopThreeByViews(response.data));
        setRandomItemsTwo(getRandomItems(response.data, 3)); 
        
        setIspending(false);
    } catch(error) {
        setIspending(false);
        toast.error(error.message);

    }
    
};
  
const getTopThreeByViews = (array) => {
    return array
        .sort((a, b) => b.views - a.views)
        .slice(0, 3);
};

function getThreeMostRecentItems(data) {
    // Sort the data by the created_at attribute in descending order
    data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    // Get the top three most recent items
    const threeMostRecentItems = data.slice(0, 3);

    return threeMostRecentItems;
}
useEffect(() => { 
    fetchVideos();
}, [ ]); 

return ( 
        <div className="home-videos">
            <Helmet>
                <title>Campus Videos</title>
                <meta name="description" content="Dive into our extensive library of videos covering a wide range of Shows. From podcasts and interviews to event highlights and creative projects, our video content is curated to inspire, educate, and entertain the campus community. Stay updated with the latest releases and trending videos." />  
            </Helmet>
            <Toaster />
            <div className="nav relative">
                <Navbar navlinks='show' />
            </div>
            <div className="container mx-auto"> 
            <div className="rounded-3xl h-[70vh] md:h-[600px] bg-black">
                <h1 className="text-2xl md:text-6xl mt-7 ms-7  text-white">Quickies</h1>
                <iframe src="https://widget.taggbox.com/149585" className="absolute md:relative"  style={{width: '100%', height: '100%', zIndex: '23333'}}></iframe>

            </div>     
           
            </div> 
            {isPending ? (
            <div>
                <Skeleton />
                <Skeleton /> 
                <Skeleton />
            </div>
        ) 
                : 
                (
                     videos.length > 3 ? ( <div>
                        <div>
                        <div className="container px-4 mx-auto text-white my-12">
                             <h1 className="text-4xl md:text-6xl font-bold">Trending Now</h1>
                             <div className="mt-6 grid gap-4 md:grid-cols-3">
                                 {setOne.map((video) => (
                                    <div className="thumb" key={video.id}>
                                        <div className="transition-all hover:shadow-xl hover:shadow-[#BF3B7D]">
                                            <Link to={`/videos/watch/${video.id}`}>
                                                <div className="thumbnail relative">
                                                    <img src={video.thumbnail} alt="no image"  />
                                                    <FaPlayCircle  className="absolute top-1/2 left-1/2 z-60 text-2xl " />
                                                </div>
                                            </Link>
                                        </div> 
                                        <div className="thumb-footer flex mt-7">
                                        <Link className="block" to={`/videos/category/${video.type}`}>
                                            <img src={imagePaths.filter((path) => (path.split('/').pop().split('.').shift()) == video.type)}  alt="no thumb" />
                                        </Link>
                                            <p className="ml-4 text-start text-sm my-auto md:text-lg">{video.description}</p>
                                        </div>
                                    </div> 
                                 ))}  
                             </div> 
                         </div>  
                         <div className="container px-4 mx-auto text-white my-12">
                             <h1 className="text-4xl md:text-6xl font-bold">Continue watching</h1>
                             <div className="mt-6 grid gap-4 md:grid-cols-3">
                                 {setTwo.map((video) => (
                                    <div className="thumb" key={video.id}>
                                        <div className="transition-all hover:shadow-xl hover:shadow-[#BF3B7D]">
                                            <Link to={`/videos/watch/${video.id}`}>
                                                <div className="thumbnail relative">
                                                    <img src={video.thumbnail} alt="no image"  />
                                                    <FaPlayCircle  className="absolute top-1/2 left-1/2 z-60 text-2xl " />
                                                </div>
                                            </Link>
                                        </div> 
                                        <div className="thumb-footer mt-7 flex">
                                        <Link to={`/videos/category/${video.type}`}><img src={imagePaths.filter((path) => (path.split('/').pop().split('.').shift()) == video.type)}  alt="no thumb" /></Link>
                                            <p className="ml-4 text-sm my-auto md:text-lg">{video.description}</p>
                                        </div>
                                    </div> 
                                 ))}  
                             </div>
             
                         </div>  
                         <div className="container px-4 mx-auto text-white my-12" id="freshest">
                             <h1 className="text-4xl md:text-6xl font-bold">Freshest</h1>
                             <div className="mt-6 grid gap-4 md:grid-cols-3">
                                 {setThree.map((video) => (
                                    <div className="thumb" key={video.id}>
                                        <div className="transition-all hover:shadow-xl hover:shadow-[#BF3B7D]">
                                            <Link to={`/videos/watch/${video.id}`}>
                                                <div className="thumbnail relative">
                                                    <img src={video.thumbnail} alt="no image"  />
                                                    <FaPlayCircle  className="absolute top-1/2 left-1/2 z-60 text-2xl " />
                                                </div>
                                            </Link>
                                        </div>  
                                        <div className="thumb-footer mt-7 flex">
                                            <Link to={`/videos/category/${video.type}`}><img src={imagePaths.filter((path) => (path.split('/').pop().split('.').shift()) == video.type)}  alt="no thumb" /></Link>
                                            <p className="ml-4 text-sm my-auto md:text-lg">{video.description}</p>
                                        </div>
                                    </div> 
                                 ))}  
                             </div>
             
                         </div>  
                        </div>
                         </div>) : (
                            <div className="container px-4 mx-auto text-white my-12">
                                   <h2 className="text-white font-bold md:text-7xl text-center">Upload More videos</h2> 
                            </div> 
                         )
                ) }
           
            
             
            <Footer />
        </div>
     );
}
 
export default HomeVideos;