
import { waveform } from 'ldrs'; 
import { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import  axios  from 'axios';
const UpdatePassword = ({userId}) => {
    waveform.register();

    const [oldpassword, setOldpassword] = useState('');
    const [newpassword, setNewpassword] = useState('');
    const [confirmpassword, setConfirmpassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData();
        if(newpassword === confirmpassword){
            setIsLoading(true);
            data.append('oldpassword', oldpassword);
            data.append('newpassword', newpassword); 
            data.append('confirmpassword', confirmpassword); 
            data.append('id', userId);
            axios.post('https://api.campus360recreations.com/user/update_profile_password/', data)
            .then((response) => {  
                if(response.data.status === 1){
                    toast.success(response.data.message);
                    setOldpassword('');
                    setConfirmpassword('');
                    setNewpassword('');
                }else{ 
                    throw new Error(response.data.message);
                }
                setIsLoading(false); 
            }).catch((error) => {
                setIsLoading(false); 
                toast.error(error.message|| error);
            })
            
        }else{
            toast.error('Passwords Donot Match!');
        }
    }

    return ( 
        <div className="update w-full">
            <Toaster />
            <form onSubmit={handleSubmit} className="w-full about rounded-lg backdrop-blur-md min-h-screen py-1 ">
                <div className="p-2 md:p-4 w-full">
                    <div className="w-full  px-6 pb-8 mt-8 sm:rounded-lg">
                        <h2 className="pl-6 text-2xl font-bold sm:text-xl text-center md:text-start">Change Password</h2>
                        <div className="grid max-w-2xl mx-auto ">
                            <div className="flex flex-col">
                                <div className="items-center mt-8 sm:mt-14 text-[#202142]">
                                    <div
                                        className="w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                                        <div className="w-full">
                                            <label htmlFor="first_name"
                                                className="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Your
                                                Password</label>
                                            <input type="password" id="oldpass"
                                                className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                                                placeholder="Old Password" value={oldpassword} onChange={(e) => setOldpassword(e.target.value)} />
                                        </div>  
                                    </div>
                                    <div
                                        className="w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                                        <div className="w-full">
                                            <label htmlFor="first_name"
                                                className="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Your
                                                New Password</label>
                                            <input type="password" id="newpass"
                                                className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                                                placeholder="New Password" onChange={(e) => setNewpassword(e.target.value)} required />
                                        </div>  
                                    </div>
                                    <div
                                        className="w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                                        <div className="w-full">
                                            <label htmlFor="confi"
                                                className="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Your
                                                Confirm Password</label>
                                            <input type="password" id="first_name"
                                                className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                                                placeholder="Confirm password" onChange={(e) => setConfirmpassword(e.target.value)} required />
                                        </div>  
                                    </div>
                                    <div className="w-full">
                                        <button type="submit"
                                        disabled={isLoading}
                                            className="text-white button rounded-lg  text-sm w-full px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800">
                                                {isLoading ?
                                                    <l-waveform
                                                    size="25"
                                                    stroke="3.5"
                                                    speed="1" 
                                                    color="white" 
                                                    ></l-waveform> : 'Update Password' }
                                            </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
     );
}
 
export default UpdatePassword;