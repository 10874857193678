import Navbar from "./Navbar";
import Footer from "./Footer"; 
import equalizer from './imgs/equalizer 1.png';
import { useState, useEffect } from "react"; 
import axios from "axios";
import { Link } from "react-router-dom";
import Skeleton from "./Skeleton";
import FormatLikes from "./components/FormatLikes";
import { FaPlayCircle } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

 
const Spaces = () => {

const [isPending, setIspending] = useState(false); 
const [spaces, setSpaces] = useState([]);
const [setOne, setRandomItemsOne] = useState([]);
const [setTwo, setRandomItemsTwo] = useState([]);
const [setThree, setRandomItemsThree] = useState([]);

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }; 

  
function getThreeMostRecentItems(data) {
    // Sort the data by the created_at attribute in descending order
    data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    // Get the top three most recent items
    const threeMostRecentItems = data.slice(0, 3);

    return threeMostRecentItems;
}

const getTopThreeByViews = (array) => {
    return array
        .sort((a, b) => b.views - a.views)
        .slice(0, 3);
};

  const getRandomItems = (array, numItems) => {
    const shuffledArray = shuffleArray([...array]);
    return shuffledArray.slice(0, numItems);
  };

const fetchSpaces = async () => {
    setIspending(true);
    try{
        const response = await axios.get('https://api.campus360recreations.com/spaces/'); 
        setSpaces(response.data);
        //console.log(response.data); 
        setRandomItemsOne(getTopThreeByViews(response.data));
        setRandomItemsTwo(getRandomItems(response.data, 3));
        setRandomItemsThree(getThreeMostRecentItems(response.data));
        setIspending(false);
    } catch(error) {
        setIspending(false);
        console.log('an error occured'+ error);

    }
};

useEffect(() => { 
    fetchSpaces();
}, [ ]); 
 

    return ( 
        <div className="spaces">
            <Helmet>
                <title>Spaces</title>
                <meta name="description" content="Dynamic and engaging podcasts for the youth to have fun, learn, and grow. These are present aged by the award winning youth presenters bringing you deeper into the trending topics of the campus community." />  
            </Helmet>
            <div className="nav relative">
                <Navbar navlinks='show' />
            </div> 
            {isPending ? (
                <div>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </div>
            ) 
                : 
                ( 
                    spaces.length > 3 ? ( <div>
                        <div className="container px-4 mx-auto text-white my-12">
                       <h1 className="text-4xl md:text-6xl font-bold">Trending Now</h1>
                       <div className="mt-6 grid gap-4 md:grid-cols-3">
                           {setOne.map((space) => ( 
                            <div className="thumb" key={space.id}> 
                                <Link to={`/spaces/watch/${space.id}`}> 
                                    <div className="thumbnail">
                                        <img src={space.thumbnail} alt="no-image" />
                                        <FaPlayCircle  className="absolute top-1/2 left-1/2 z-60 text-2xl " />
                                    </div>
                                </Link>
                                <div className="thumb-footer mt-7 flex">
                                
                                    <img src={equalizer} className="inline" alt="no thumb" />
                                
                                    <div className="ml-4 ">
                                        <p className="text-sm md:text-lg">{space.title}</p>
                                        <div className="listeners">
                                            <p className="mr-5 flex" > <FormatLikes likes={space.views}  /> <p className="ml-1">listeners</p></p>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                           ))} 
                       </div>
       
                   </div> 
                   <div className="container px-4 mx-auto text-white my-12">
                       <h1 className="text-4xl md:text-6xl font-bold">Continue Listening</h1> 
                       <div className="mt-6 grid gap-4 md:grid-cols-3">
                           {setTwo.map((space) => ( 
                            <div className="thumb" key={space.id}> 
                                <Link to={`/spaces/watch/${space.id}`}> 
                                    <div className="thumbnail relative">
                                        <img src={space.thumbnail} alt="no-image" /> 
                                        <FaPlayCircle  className="absolute top-1/2 left-1/2 z-60 text-2xl " />
                                    
                                    </div>
                                </Link>
                                <div className="thumb-footer mt-7 flex">
                                
                                    <img src={equalizer} className="inline" alt="no thumb" />
                                
                                    <div className="ml-4 ">
                                        <p className="text-sm md:text-lg">{space.title}</p>
                                        <div className="listeners">
                                            <p className="mr-5">122 listeners</p>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                           ))} 
                       </div>
                   </div>
                   <div className="container px-4 mx-auto text-white my-12">
                       <h1 className="text-4xl md:text-6xl font-bold">Freshest</h1> 
                       <div className="mt-6 grid gap-4 md:grid-cols-3">
                           {setThree.map((space) => ( 
                            <div className="thumb" key={space.id}> 
                                <Link to={`/spaces/watch/${space.id}`}> 
                                    <div className="thumbnail">
                                        <img src={space.thumbnail} alt="no-image" />
                                    </div>
                                </Link>
                                <div className="thumb-footer mt-7 flex">
                                
                                    <img src={equalizer} className="inline" alt="no thumb" />
                                
                                    <div className="ml-4 ">
                                        <p className="text-sm md:text-lg">{space.title}</p>
                                        <div className="listeners">
                                            <p className="mr-5">122 listeners</p>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                           ))} 
                       </div>
                   </div>    
                   </div>): (
                   <div className="container px-4 mx-auto text-white my-12">
                        <h2 className="text-white font-bold md:text-7xl text-center">Upload More Spaces</h2> 
                    </div>)
            )}
            <Footer />
        </div>
     );
}
 
export default Spaces;