import React, { useEffect, useRef, useState } from 'react';
import { FaPlay, FaPause, FaBackward, FaForward } from 'react-icons/fa';
import axios from 'axios';

const AudioPlayer = ({ source, thumbnail, id }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const intervalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      playAudio();
    } else {
      pauseAudio();
    }
  };

  const playAudio = () => {
    if (audioRef.current && !isLoading) {
      audioRef.current.play().catch(error => {
        console.error('Error playing audio:', error);
      });
      setIsPlaying(true);
    }
  };

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleLoadedData = () => {
    setDuration(audioRef.current.duration);
    setIsLoading(false);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleForward = () => {
    audioRef.current.currentTime += 10;
  };

  const handleBackward = () => {
    audioRef.current.currentTime -= 10;
  };

  const checkDuration = () => {
    if (audioRef.current) {
      const { duration, currentTime } = audioRef.current;
      const viewedPercentage = (currentTime / duration) * 100;
      if (viewedPercentage >= 2) {
        axios.post('https://api.campus360recreations.com/viewed/space_views/', { space_id: id })
          .then(response => {
            if (response.data.status === 1) {
              clearInterval(intervalRef.current);
            } else {
              throw new Error("Failed to set view");
            }
          })
          .catch(error => {
            console.error(error.message);
          });
      }
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.load();
      setIsPlaying(false);
      setIsLoading(true);
    }
  }, [source]);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying && !isLoading) {
        audioRef.current.play().catch(error => {
          console.error('Error playing audio:', error);
        });
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying, isLoading]);

  useEffect(() => {
    intervalRef.current = setInterval(checkDuration, 2000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div className="audio-player bg-cover bg-center p-4 rounded-lg shadow-lg flex flex-col items-center w-full text-white" style={{ backgroundImage: `url(${thumbnail})` }}>
      <audio
        ref={audioRef}
        src={source}
        onLoadedData={handleLoadedData}
        onTimeUpdate={handleTimeUpdate}
      />
      <div className='w-full flex flex-col p-2 items-center rounded-xl backdrop-blur-md bg-white/30 mt-auto'>
        <div className="controls mx-auto flex items-center space-x-4">
          <button onClick={handleBackward} className="focus:outline-none">
            <FaBackward />
          </button>
          <button onClick={togglePlayPause} className="focus:outline-none">
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>
          <button onClick={handleForward} className="focus:outline-none">
            <FaForward />
          </button>
        </div>
        <div className="progress w-full my-2">
          <div className="progress-bar bg-gray-300 h-1 w-full">
            <div className="bg-blue-600 h-1" style={{ width: `${(currentTime / duration) * 100}%` }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
