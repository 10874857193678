// ResetRequest.js
import React, { useState } from 'react';
import axios from 'axios';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Toaster, toast } from 'react-hot-toast'; 
import heroimage from '../imgs/IMG_87075.jpg'; 
import { waveform } from 'ldrs'; 
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const ResetRequest = () => {
  const [email, setEmail] = useState(''); 
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmed, setIsconfirmed] = useState(false);
  
  waveform.register();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post('https://api.campus360recreations.com/recover/password/', { email });
       if(response.data.status != 0){
            toast.success('Verification Code Sent!');
            setIsconfirmed(true);
            setIsLoading(false);
       }else{
        throw new Error(response.data.message);
        
       }
    } catch (error) {
        toast.error(error.message);
        setIsLoading(false); 
    }
  };
  const nav = useNavigate();
  const back = () => {
        nav(-1);
  }
  return (
    <div className="text-white">  
    <Toaster /> 
    <div className="flex backdrop-blur-sm bg-black/30 cont bg-cover bg-no-repeat bg-blend-multiply bg-center justify-center" style={{minHeight: '100vh', backgroundImage: `url(${heroimage})`}}>
      <Navbar /> 
      <div className="py-7 content w-3/4 md:mt-30 mx-2 my-auto md:py-10 px-4 rounded-xl font-bold text-center">
        <h1 className="primary text-2xl lg:text-5xl">Confirm Email</h1>
        <form onSubmit={handleSubmit} className="mx-3">
          <div className="flex flex-col items-center">
          <input onChange={(e) => setEmail(e.target.value)} value={email} required type="email" autoComplete="on" className="rounded mx-auto mt-4 font-normal py-4 w-full md:w-2/3 px-3 focus:outline-none" placeholder="Enter Your Email Address" />
            
            {isConfirmed ?(
                <Link to="/forgot_password/resetPassword" className="button rounded-xl block w-1/2 mt-5 mx-auto py-2" >Continue</Link>
            ) : (
                <button type="submit" disabled={isLoading} className="button rounded-xl block w-1/2 mt-5 mx-auto py-2">
                {isLoading ? (
                  <l-waveform size="25" stroke="3.5" speed="1" color="white"></l-waveform>
                ) : (
                  'Submit'
                )}
              </button>
            )}
            
            <div className="my-4">
                <div className=" mx-auto md:text-xl font-normal md:w-2/3 text-start"> 
                  <button onClick={back} className="links text-start me-auto">Back</button>  
              </div>
          </div>
          </div>
        </form>
      </div>
    </div>
    <Footer className="mt-auto" /> 
  </div>  
  );
};

export default ResetRequest;
