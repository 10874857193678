import Navbar from "./Navbar";
import Footer from "./Footer";
import avator from "./imgs/avator.webp";
import { toast, Toaster } from "react-hot-toast";
import { useEffect, useState } from "react"; 
import axios from "axios";
import { FileUploader } from "react-drag-drop-files";
import { waveform } from 'ldrs';
import UpdatePassword from "./components/UpdatePassword"; 
import Loader from "./components/Loader";
import { Helmet } from 'react-helmet';


const ProfilePage = () => {
    const fileTypes = ["JPG", "JPEG","PNG", "WEBP"];
    const userId = localStorage.getItem('user'); 
    const [file, setFile] = useState(null); 

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [contact, setContact] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [img, setimg] = useState('');
    const [checkImg, setImageExists] = useState(null); 
    const [fetchdata, setfetchdata] = useState(false)
    const [updatePassComp , setUpdatepasscomp] = useState(true);

    const handleChange = (file) => {    
        setFile(file); 
                 
    };

    const changepasscomp = (res) => {
        setUpdatepasscomp(res);
    }

    waveform.register();


    useEffect(() => {
        fetchUser();
    }, []);

    const fetchUser = async () => {
        setfetchdata(true)
        try {
            const response = await axios.post('https://api.campus360recreations.com/users/user_records', {'user': userId});
                setUsername(response.data.username);
                setEmail(response.data.email);
                setAddress(response.data.address);
                setContact(response.data.phone);
                setimg(response.data.img);  
            const img = new Image();
            img.src = response.data.img;
            
            img.onload = () => setImageExists(true);
            img.onerror = () => setImageExists(false);
            setfetchdata(false);
        } catch (error) {
            console.log(error);
            toast.error(error.message);
            setfetchdata(false);
        }
    }

    const errorImageSize = () => {
        toast.error('Image size is too big!');
    }

    const handleSubmit = (e) => {   
        e.preventDefault();
        const data = new FormData();
        data.append('file', file);
        data.append('username', username);
        data.append('email', email);
        data.append('address', address);
        data.append('contact', contact);
        data.append('user_id', userId);
        setIsLoading(true);
        axios.post('https://api.campus360recreations.com/user/update_profile/', data)
        .then((response) => {  
            if(response.data.status == 1 ){ 
                toast.success(response.data.message);
                setIsLoading(false);
            }
        })
        .catch((error) => {
            toast.error(error.message);
            setIsLoading(false);
        })
    }

    return ( 
        <div className="profile">
            <Helmet>
                <title>Profile</title>  
            </Helmet>
             <div><Toaster/></div>
                <div className="nav relative">
                    <Navbar navlinks='show' />
                </div>
                <div className="w-full text-white my-12">
                        <div className="w-full  flex flex-col gap-5 md:px-16 lg:px-28 md:flex-row text-white">
                            <aside className="hidden py-4 md:w-1/3 lg:w-1/4 md:block">
                                <div className="sticky about rounded-lg flex flex-col gap-2 p-4 text-sm border-r border-indigo-100 top-12">
                                    
                                    <h2 className="pl-3 mb-4 text-2xl font-semibold">Settings</h2>

                                    <button
                                    onClick={() => changepasscomp(true)}
                                    className="flex items-center px-3 py-2.5 font-bold bg-white  text-indigo-900 border rounded-full">
                                        Your Profile
                                    </button>
                                    <button
                                    onClick={() => changepasscomp(false)}
                                        className="flex items-center px-3 py-2.5 font-semibold hover:text-white  hover:text-indigo-900 hover:border hover:rounded-full">
                                        Change Password
                                    </button>
                                    <a href="#"
                                        className="flex items-center px-3 py-2.5 font-semibold hover:text-white  hover:text-indigo-900 hover:border hover:rounded-full">
                                        Language
                                    </a>
                                    <a href="#"
                                        className="flex items-center px-3 py-2.5 font-semibold hover:text-white  hover:text-indigo-900 hover:border hover:rounded-full">
                                        Video Quality
                                    </a>
                                    {/* <a href="#"
                                        className="flex items-center px-3 py-2.5 font-semibold hover:text-white  hover:text-indigo-900 hover:border hover:rounded-full">
                                        Dark Mode
                                    </a> */}
                                    {/* <a href="#"
                                        className="flex items-center px-3 py-2.5 font-semibold hover:text-indigo-900 hover:border hover:rounded-full  ">
                                        Notifications
                                    </a>
                                    <a href="#"
                                        className="flex items-center px-3 py-2.5 font-semibold hover:text-indigo-900 hover:border hover:rounded-full  ">
                                        PRO Account
                                    </a> */}
                                </div>
                            </aside>
                            <div>
                                {fetchdata ? 
                                (<Loader/>):(
                                    <div>
                                    {updatePassComp ? (
                                        <form onSubmit={handleSubmit} className="w-full about rounded-lg backdrop-blur-md min-h-screen py-1 ">
                                            <div className="p-2 md:p-4">
                                                <div className="w-full mx-auto px-6 pb-8 mt-8 sm:max-w-xl sm:rounded-lg">
                                                    <h2 className="pl-6 text-2xl font-bold sm:text-xl text-center md:text-start flex">Public Profile</h2>
                                                    
                                                    <div className="grid max-w-2xl mx-auto mt-8">
                                                        <div className="flex flex-col items-center space-y-5 sm:flex-row sm:space-y-0">
            
                                                        {file ? ( 
                                                            <img className="object-cover w-40 h-40 p-1 rounded-full ring-2 ring-indigo-300 dark:ring-indigo-500"
                                                            src={URL.createObjectURL(file)}
                                                            alt="Bordered avatar" />
                                                             ) : ( 
                                                                checkImg ? (
                                                                    <img className="object-cover w-40 h-40 p-1 rounded-full ring-2 ring-indigo-300 dark:ring-indigo-500"
                                                                src={img}
                                                                alt="Bordered avatar" />
                                                                ): (
                                                                    <img className="object-cover w-40 h-40 p-1 rounded-full ring-2 ring-indigo-300 dark:ring-indigo-500"
                                                                    src={avator}
                                                                    alt="Bordered avatar" />
                                                                )
                                                                
                                                             )} 
            
                                                            <div className="sm:ml-8">
                                                                <p><strong>Username: </strong> {username}</p>
                                                                <p className="mt-4"><strong>Email: </strong> {email}</p>
                                                            </div>
                                                        </div>
            
                                                        <div className="mt-6">
                                                        <FileUploader handleChange={handleChange} name="file" types={fileTypes} maxSize="10" onSizeError={errorImageSize}  />
            
                                                        </div>
            
                                                        <div className="items-center mt-8 sm:mt-14 text-[#202142]">
                                                            <div
                                                                className="w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                                                                <div className="w-full">
                                                                    <label htmlFor="first_name"
                                                                        className="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Your
                                                                        Username</label>
                                                                    <input type="text" id="first_name"
                                                                        className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                                                                        placeholder="Your first name" value={username} onChange={(e) => setUsername(e.target.value)} required />
                                                                </div> 
            
                                                            </div>
            
                                                            <div className="mb-2 sm:mb-6">
                                                                <label htmlFor="email"
                                                                    className="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Your
                                                                    email</label>
                                                                <input type="email" id="email"
                                                                    className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                                                                    placeholder="your.email@mail.com" value={email} required onChange={(e) => setEmail(e.target.value)}  />
                                                            </div>
            
                                                            <div className="mb-2 sm:mb-6">
                                                                <label htmlFor="profession"
                                                                    className="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Address</label>
                                                                <input type="text" id="profession"
                                                                    className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                                                                    placeholder="your address" required value={address} onChange={(e) => setAddress(e.target.value)} />
                                                            </div>
            
                                                            <div className="mb-2 sm:mb-6">
                                                                <label
                                                                    className="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Your Contact</label>
                                                                <input type="text" id="contact"
                                                                    className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                                                                    placeholder="your contact" required  value={contact} onChange={(e) => setContact(e.target.value)} />
                                                            </div>
            
                                                             
            
                                                            <div className="w-full">
                                                                <button type="submit"
                                                                disabled={isLoading}
                                                                    className="text-white button rounded-lg  text-sm w-full px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800">
                                                                        {isLoading ?
                                                                            <l-waveform
                                                                            size="25"
                                                                            stroke="3.5"
                                                                            speed="1" 
                                                                            color="white" 
                                                                            ></l-waveform> : 'Update Profile' }
                                                                    </button>
                                                            </div>
            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        ) : (
                                            <UpdatePassword className="w-full grow" userId = {userId} />
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="about md:hidden p-3">
                                <div className="flex justify-around">
                                    <button className="links" onClick={() => changepasscomp(true)}>Profile</button>|
                                    <button className="links" onClick={() => changepasscomp(false)}>Change Password</button>
                                </div>
                            </div>
                        </div> 
                </div>
            <Footer />
        </div>
     );
}
 
export default ProfilePage;