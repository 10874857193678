import { Link } from 'react-router-dom';
import logo from './imgs/campus-logo.png';

const Footer = () => {
 
        const scrollToTop = () => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smooth scrolling
          }); 
          }
          
    return ( 
        <div className="footer">
            <div className="container mx-auto">
                <div className="flex justify-between items-center">
                    <div className='logo'>
                        <img src={logo} alt="no logo" />
                    </div>
                    <div className="flex justify-between flex-grow md:mx-10 text-white">
                        <Link to="/about" className='text-xs md:text-lg mx-1'>About Us</Link>
                        <Link to="/terms" className='text-xs md:text-lg mx-1'>Terms & conditions</Link>
                        <Link to="/privacy" className='text-xs md:text-lg mx-1'>Privacy</Link>
                        <Link to="" className='text-xs md:text-lg mx-1'>Follow Us</Link>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" onClick={scrollToTop} fill="#CD3981" width="40px" height="40px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24"><path d="M16.9,13.4l-4.2-4.2l0,0c-0.4-0.4-1-0.4-1.4,0l-4.2,4.2c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0l3.5-3.5l3.5,3.5c0.2,0.2,0.4,0.3,0.7,0.3l0,0c0.3,0,0.5-0.1,0.7-0.3C17.3,14.4,17.3,13.8,16.9,13.4z"/></svg>
                    </div>
                </div>
                
            </div>
        </div>
    );
}
 
export default Footer;