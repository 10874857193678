import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import { waveform } from "ldrs";
import Navbar from "./Navbar";
import Footer from "./Footer";
import heroimage from "./imgs/IMG_87075.jpg";
import { GoogleLogin } from "@react-oauth/google";
import { Helmet } from 'react-helmet';


const LogIn = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function verifyToken(token) {
    try {
      const { data } = await axios.get(
        `https://oauth2.googleapis.com/tokeninfo?id_token=${token}`
      );
      googleLogin(data.email);
    } catch (e) {
      toast.error(e.message);
    }
  }

  const googleLogin = async (email) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://api.campus360recreations.com/user/google_login/",
        { email: email }
      );
      if (response.data.status !== 0) {
        toast.success("Logging In...");
        setTimeout(() => {
          localStorage.setItem("login", true);
          localStorage.setItem("user", response.data.user);
          navigate("/videos");
          setIsLoading(false);
        }, 1000);
      } else {
        throw new Error(response.data.message);
      }
    } catch (e) {
      toast.error(e.message);
      setIsLoading(false);
    }
  };

  waveform.register();
  const responseMessage = (response) => {
    // console.log(response);
    const accessToken = response?.credential;
    if (accessToken) {
      verifyToken(accessToken);
    }
  };
  const errorMessage = (error) => {
    toast.error(error);
    // console.log(error);
  };

  useEffect(() => {
    let login = localStorage.getItem("login");
    if (login) {
      navigate("/videos");
    }
    let loginstatus = localStorage.getItem("loginstatus");
    if (loginstatus) {
      toast.success(loginstatus);
      setTimeout(() => {
        localStorage.clear();
      }, 3000);
    }
  }, [navigate]);

  const handleLogin = (e) => {
    e.preventDefault();
    const data = new FormData();
    if (user === "" || password === "") {
      toast.error("Please fill in the form!");
    } else {
      data.append("email", user);
      data.append("password", password);
      setIsLoading(true);
      axios
        .post("https://api.campus360recreations.com/user/login/", data)
        .then((response) => {
          if (response.data.status !== 0) {
            toast.success("Logging In...");
            setTimeout(() => {
              localStorage.setItem("login", true);
              localStorage.setItem("user", response.data.user);
              navigate("/videos");
              setIsLoading(false);
            }, 1000);
          } else {
            throw new Error(response.data.message);
          }
        })
        .catch((error) => {
          toast.error(error.message);
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="text-white">
      <Helmet>
        <title>Login</title>
        <meta
          name="description"
          content="Join the Campus 360 community by signing in to access exclusive content, open up chances for  scholarships, funds with many more and stay informed about what rolls around all the universities in the country.."
        />
      </Helmet>
      <Toaster />
      <div
        className="flex backdrop-blur-sm bg-black/30 cont bg-cover bg-no-repeat bg-blend-multiply bg-center justify-center"
        style={{ minHeight: "100vh", backgroundImage: `url(${heroimage})` }}
      >
        <Navbar />
        <div className="py-7 content w-3/4 md:mt-20 mx-2 my-auto md:py-10 px-4 rounded-xl font-bold text-center">
          <h1 className="primary text-2xl lg:text-5xl">Sign In</h1>
          <form onSubmit={handleLogin} className="mx-3">
            <div className="flex flex-col items-center">
              <input
                onChange={(e) => setUser(e.target.value)}
                type="email"
                autoComplete="on"
                className="rounded mx-auto mt-4 font-normal py-4 w-full md:w-2/3 px-3 focus:outline-none"
                placeholder="Email Address"
              />
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                className="rounded mx-auto mt-4 py-4 w-full md:w-2/3 px-3 focus:outline-none"
                placeholder="Enter Password"
              />

              <div className="md:my-5 my-3 mx-auto md:text-xl font-normal md:w-2/3 text-start">
                <div className="text-white">
                  <GoogleLogin
                    onSuccess={responseMessage}
                    onError={errorMessage}
                  />
                </div>
              </div>
              <div className="my-2 mx-auto md:text-xl font-normal md:w-2/3 text-start">
                <div className="text-white flex justify-between">
                  <Link to="/signup" className="links text-xs mr-2 md:text-xl">
                    Create An Account?
                  </Link>
                  <Link
                    to="/forgot_password"
                    className="links text-xs mr-2 md:text-xl"
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className="button rounded-xl block w-1/2 mx-auto py-2"
              >
                {isLoading ? (
                  <l-waveform
                    size="25"
                    stroke="3.5"
                    speed="1"
                    color="white"
                  ></l-waveform>
                ) : (
                  "Log In"
                )}
              </button>
              <div className="my-4"></div>
            </div>
          </form>
        </div>
      </div>
      <Footer className="mt-auto" />
    </div>
  );
};

export default LogIn;
