import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import HomeVideos from './HomeVideos';
import Login from './Login';
import Shows from './Shows';
import Spaces from './Spaces';
import WatchPage from './WatchPage';
import Segments from './Segments';
import About from './About';
import Terms from './Terms';
import Privacy from './Privacy';
import UploadVideo from './UploadVideo';
import UploadSpace from './UploadSpace';
import SpacesWatch from './SpacesWatch';
import SignUp from './SignUp';
import ProfilePage from './Profile';
import Protected from './protected';
import HelpPage from './Help';
import Merch from './Merch';
import NotFound from './components/NotFound';
import Internship from './Internship';
import ResetRequest from './components/ResetRequest';
import ResetPassword from './components/ResetPassword';

function App() { 
  
// const secrs = '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';
  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/videos" element={<HomeVideos />} />
            <Route path="/shows" element={<Shows />} />
            <Route path="/spaces" element={<Spaces />} />
            <Route path="/spaces/watch/:id" element={<SpacesWatch />} />
            <Route path="/videos/watch/:id" element={<WatchPage />} />
            <Route path="/videos/category/:id" element={<Segments />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/merch" element={<Merch />} />
            <Route path="/internship" element={<Internship />} />
            <Route path="/forgot_password" element={<ResetRequest />} />
            <Route path="/forgot_password/resetPassword" element={<ResetPassword />} />
            <Route path="/upload-video" element={<UploadVideo />} />
            <Route path="/upload-space" element={<UploadSpace />} />
            <Route path="/profile" element={<Protected Component={ProfilePage} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
