import { grid } from 'ldrs' 
const Loader = () => {
    grid.register()

    return ( 
        <div className="fixed inset-0  flex items-center justify-center z-50 bg-white/5 backdrop-blur-md ">
            <l-grid
            size="60"
            speed="1.5" 
            color="white" 
            ></l-grid>
        </div>
     );
}
 
export default Loader;