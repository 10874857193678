import React from 'react';

const formatLikes = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k';
  } else {
    return num.toString();
  }
};

const FormatLikes = ({ likes }) => {
  return (
    <div>
      {formatLikes(likes)}
    </div>
  );
};

export default FormatLikes;
