import Navbar from "./Navbar";
import Footer from "./Footer";
import SideBar from "./SideBar";
import { Helmet } from 'react-helmet';



const Privacy = () => {
    return ( 
        <div className="privacy">
            <Helmet>
                <title>Privacy</title>
                <meta name="description" content="Campus 360 privacy policies." />  
            </Helmet>
            <div className="nav relative" >
                <Navbar page='Privacy' />
            </div>
            <div className="container mx-auto mt-10 text-white">
              <div className="flex">
                <div className="hidden md:block">
                    <SideBar />
                </div>
                <div className="cont rounded-xl p-8 mb-8 md:ml-10 mx-3 w-full"> 
                    <h3 className="primary text-lg md:text-2xl mt-6">Privacy Policy</h3>
                     <p>At Campus 360, safeguarding your privacy is a top priority for us. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you interact with our website and services.</p>

                    <h3 className="primary text-lg md:text-2xl mt-6">Information We Collect:</h3>
                    <p>Personal Information: When you engage with us, we may collect personal details such as your name, email address, phone number, and other contact information. This information is provided voluntarily by you.</p>

                    <h3 className="primary text-lg md:text-2xl mt-6">Who we are:</h3>
                    <p>Usage Data: We may automatically gather data about your interactions with our website, including your IP address, browser type, pages visited, and other usage patterns. This helps us enhance your browsing experience and improve our services.</p>

                    <h3 className="primary text-lg md:text-2xl mt-6">How We Use Your Information</h3>
                    <p>Service Provision: Your personal information is utilized to deliver and maintain our services efficiently. This includes communication regarding updates, promotions, and other relevant information.</p>
                    <p>Website Improvement: We analyze usage data to enhance the functionality and usability of our website. This allows us to tailor our content to better meet your needs and preferences.</p>
                    <p>Legal Compliance: We may use your information to comply with legal obligations, such as responding to legal requests or preventing fraudulent activities.</p>

                    <h3 className="primary text-lg md:text-2xl mt-6">Data Security:</h3>
                    <p>Your security is paramount to us. We employ robust security measures to safeguard your personal information from unauthorized access, disclosure, alteration, or destruction.</p>
                    
                    <h3 className="primary text-lg md:text-2xl mt-6">Your Rights:</h3>
                    <p>You have the right to access, correct, or delete your personal information at any time. Additionally, you may choose to opt-out of receiving promotional communications from us.</p>

                    <h3 className="primary text-lg md:text-2xl mt-6">Changes to This Policy:</h3>
                    <p>We may update this Privacy Policy periodically to reflect changes in our practices or regulatory requirements. We will notify you of any significant updates by posting the revised policy on our website.</p>

                    <h3 className="primary text-lg md:text-2xl mt-6">Contact Us:</h3>
                    <p>If you have any questions or concerns about our Privacy Policy, please reach out to us at support@campus360recreations.com. We value your feedback and are committed to addressing any inquiries promptly. By continuing to use our website and services, you signify your acceptance of this Privacy Policy.</p>

                  </div>
              </div>
            </div>
            <Footer /> 
        </div>
     );
}
 
export default Privacy;