import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Protected = ({ Component }) => {
    const navigate = useNavigate(); 
    useEffect (() => {
        let login = localStorage.getItem('login');
        if(!login){
            localStorage.setItem('loginstatus', 'Please login to view this page');
            navigate('/login', {replace: true});
        }
    }, []);
    return ( <Component />  );
}
 
export default Protected;