import Navbar from "./Navbar";
import Footer from "./Footer";  
import { Link } from "react-router-dom";
import confessionsImg from "./imgs/camp/360 Confessions.jpg";
import foodhub from './imgs/camp/360 Food hub.png'; 
import mentor from './imgs/camp/360 Mentor.jpg';
import news from "./imgs/camp/360 News.jpg";
import bliss from "./imgs/camp/360 Evening Bliss.jpg";
import poll from "./imgs/camp/360 Fashion Poll.jpg";
import fitness from "./imgs/camp/360 Fitness.jpg";
import thought from "./imgs/camp/360 Food 4 Thought.jpg";
import games from "./imgs/camp/360 Gamez.jpg";
import hotseat from "./imgs/camp/360 HotSeat.jpg";
import opinion from "./imgs/camp/360 Opinion.jpg";
import tech from "./imgs/camp/360 Tech Series.jpg";
import wid from "./imgs/camp/360 WID.jpg"; 
import sports from "./imgs/camp/360 Sports.jpg"; 
import { Helmet } from 'react-helmet';


const Shows = () => {

    const imagePaths = [sports, foodhub, mentor, news, confessionsImg, bliss, poll, fitness, thought, games, hotseat, opinion, tech, wid];
 
    return ( 
        <div className="shows">
             <Helmet>
                <title>Shows</title>
                <meta name="description" content="Explore a variety of engaging shows produced by Campus 360. From fashion and sports to confessions and campus news, our shows are designed to entertain, inform, and connect the student community. Tune in for the latest episodes and enjoy diverse content created by students, for students." />  
            </Helmet>
            <div className="nav relative">
                <Navbar navlinks='show' />
            </div> 
            <div className="container mx-auto mt-10 px-3 text-white">
             <h1 className="text-4xl md:text-6xl font-bold">All Shows</h1>
             <div className="mt-6 grid gap-4 md:grid-cols-3">
                    {imagePaths.map((image)=> (
                        <div className="thumb md:my-10" key={image}>
                            <div className="thumbnail">
                                <img src={image} alt="no-image" className="rounded-xl" />
                            </div>
                            <div className="thumb-footer mt-6 flex">
                                <Link className="links" to={`/videos/category/${image.split('/').pop().split('.').shift()}`}><h1 className="text-2xl md:text-4xl font-bold">{image.split('/').pop().split('.').shift()}</h1></Link>
                            </div>
                        </div> 
                    ))}
            </div> 
            </div>
            <Footer />
        </div>
     );
}
 
export default Shows;