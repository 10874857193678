import Navbar from "./Navbar";
import Footer from "./Footer";
import SideBar from "./SideBar";

const Terms = () => {
    return (  
        <div className="terms"> 
            <div className="nav relative">
                <Navbar page='Terms & Conditions' />
            </div>
            <div className="container mx-auto mt-10 text-white">
              <div className="flex">
                <div className="hidden md:block">
                    <SideBar />
                </div>
                <div className="cont rounded-xl p-8 mb-8 mx-3 md:ml-10 w-full"> 
                    <p>
                    Welcome to Campus 360! These Terms and Conditions (the "Terms") govern your use of our website, located at campus360recreations.com, and any services, features, and content provided by Campus 360 (referred to as "we," "us," or "our").
                    By accessing and using the Campus 360 website, you agree to these Terms. If you do not agree with these Terms, please do not use our website. By accessing and using the Campus 360 website, you agree to these Terms. If you do not agree with these Terms, please do not use our website.
                    </p>
                    <h3 className="primary text-lg md:text-2xl mt-6">User Obligations</h3>
                    <p>You must be at least 18 years of age to use our website.</p>
                    <p>You are responsible for maintaining the confidentiality of your account information.</p>
                    <p>You agree not to use our website for any illegal or unauthorized purposes.</p>

                    <h3 className="primary text-lg md:text-2xl mt-6">Privacy Policy</h3>
                    <p>Our Privacy Policy explains how we collect, use, and protect your personal information. By using our website, you consent to the terms of our Privacy Policy.</p>
                    
                    <h3 className="primary text-lg md:text-2xl mt-6">Intellectual Property</h3>
                    <p>The content on our website, including text, graphics, images, and logos, is protected by intellectual property rights and may not be used without our permission.</p>

                    <h3 className="primary text-lg md:text-2xl mt-6">User-Generated Content</h3>
                    <p>Users may post content on our website. By doing so, you grant us a non-exclusive, worldwide, royalty-free license to use, display, and modify the content.</p>
                    <p>You are responsible for the content you post and must not violate our content guidelines.</p>

                    <h3 className="primary text-lg md:text-2xl mt-6">Third-Party Links</h3>
                    <p>Our website may contain links to third-party websites. We are not responsible for their content or privacy practices.</p>

                    <h3 className="primary text-lg md:text-2xl mt-6">Termination</h3>
                    <p>We reserve the right to terminate your access to our website at our discretion</p>

                    <h3 className="primary text-lg md:text-2xl mt-6">Disclaimer</h3>
                    <p>The information provided on our website is for general informational purposes only. We do not guarantee the accuracy, reliability, or availability of any information on the website.</p>


                  </div>
              </div>
            </div>
            <Footer /> 
        </div>
    );
}
 
export default Terms;