import Navbar from "./Navbar";
import Footer from "./Footer";
import heroimage from './merch/test2.png'; 
import { FaCartShopping } from "react-icons/fa6"; 
import shirt1 from './merch/shirt1.jpg';
import shirt2 from './merch/shirt2.jpg';
import shirt3 from './merch/shirt3.jpg';
import shirt4 from './merch/shirt4.jpg'; 
import shirt5 from './merch/IMG-20240624-WA0080.jpg';
import shirt6 from './merch/IMG-20240624-WA0076.jpg';
import shirt7 from './merch/IMG-20240624-WA0077.jpg';
import shirt8 from './merch/IMG-20240624-WA0075.jpg';
import shirt9 from './merch/IMG-20240624-WA0078.jpg'
import shirt10 from './merch/IMG-20240624-WA0081.jpg' 
import {toast, Toaster } from "react-hot-toast";
import { Helmet } from 'react-helmet';


const Merch = () => {

    const coming = () => {
        toast('Coming Soon!', {
            icon: '👏',
          });
    }
    const merch = [ 
        {
            name: '360 - Deep black - Special edition',
            price: '7.99',
            img: shirt1
        }, {
            name: '360 Color splash - Elite',
            price: '7.99', 
            img: shirt2,
        }, {
            name: '360 Color splash -Black',
            price: '7.99',
            img: shirt3,
            
        }, {
            name: '360 Hoodie - Space black',
            price: '7.99',
            img: shirt4, 
        }, {
            name: '360 Ink Black',
            price: '7.99',
            img: shirt5, 
        }, {
            name: '360 ink black- Hoodie',
            price: '7.99',
            img: shirt6, 
        }, {
            name: '360 Stripes -Black',
            price: '7.99',
            img: shirt7, 
        }, {
            name: '360 Stripes -Black',
            price: '7.99',
            img: shirt8, 
        } , {
            name: '360 premium white',
            price: '7.99',
            img: shirt9, 
        }, {
            name: '360 stripes- White',
            price: '7.99',
            img: shirt10, 
        }
    ]
    return ( 
        <div className="merch"> 
        <Helmet>
                <title>360 Merch</title>
                <meta name="description" content="Show off your high spirit with Campus 360 merchandise! Our collection includes a variety of branded items such as T-shirts , Hoodies, and more." />  
            </Helmet>
            <Toaster />
            <div className="nav relative">
                <Navbar/>
            </div>
            <div className="conatiner mt-8 rounded-lg bg-[#030303] flex mx-4 px-4 md:px-10">
                <div className="text-white md:w-1/2 flex flex-col justify-center">
                    <h1 className="text-3xl md:text-7xl">Welcome to the</h1>  
                    <h1 className="text-3xl md:text-7xl mb-6">Campus 360 Merchandise 
                    </h1> 
                    <p className="hidden md:block">Explore our Cool collection of Campus 360 merchandise, designed to not only elevate your fashion game but also to make a difference.
                    </p>
                    <p className="hidden md:block">
                    Every purchase supports our initiatives like scholarships, internships, and student programs so elevate your style and make a difference in our campus community.

                    </p>
                     <button onClick={coming} className="flex w-3/4 py-1 button md:mt-6 md:text-xl md:w-1/3 rounded-full justify-center items-center  md:py-2"> <FaCartShopping className="mr-3" />Shop Now</button>
                </div>
                <div className="ml-auto">
                    <img src={heroimage} alt="No-Img" className="mx-auto"  />
                </div>
            </div> 
            <div className="container">
                <div className="grid grid-cols-2 md:grid-cols-3 gap-1 md:gap-4 text-white">
                    {merch.map((item, index) => (
                        <div key={index} className="relative mx-2 md:my-10 my-5 flex w-full  flex-col overflow-hidden rounded-lg border border-[#B63D87] bg-[#030303] shadow-lg">
                        <a className="relative mx-3 mt-3 flex md:h-65 h-30 overflow-hidden rounded-xl" href="#">
                            <img className="object-cover w-full" src={item.img} alt="product image" />
                            <span className="absolute top-0 left-0 m-2 rounded-full bg-black px-2 text-center text-sm font-medium text-white">39% OFF</span>
                        </a>
                        <div className="mt-4 px-5 pb-5">
                            <a href="#">
                            <h5 className="md:text-xl tracking-tight ">{item.name}</h5>
                            </a>
                            {/* <div className="mt-2 mb-5 md:flex items-center justify-between">
                            <p>
                                <span className="text-3xl font-bold  ">$449</span>
                                <span className="text-sm   line-through">$699</span>
                            </p>
                            <div className="flex items-center">
                                <svg aria-hidden="true" className="h-5 w-5 text-[#B63D87]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                </svg>
                                <svg aria-hidden="true" className="h-5 w-5 text-[#B63D87]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                </svg>
                                <svg aria-hidden="true" className="h-5 w-5 text-[#B63D87]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                </svg>
                                <svg aria-hidden="true" className="h-5 w-5 text-[#B63D87]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                </svg>
                                <svg aria-hidden="true" className="h-5 w-5 text-[#B63D87]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                </svg>
                                <span className="mr-2 ml-3 rounded bg-[#B63D87] px-2.5 py-0.5 text-xs font-semibold">5.0</span>
                            </div>
                            </div> */}
                            <a onClick={coming} disabled={true} className="flex items-center justify-center rounded-md button px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                            </svg>
                            Add to cart</a>
                        </div>
                    </div> 
                    ))} 
                </div>
            </div> 

            <div> 

            </div>
            <Footer />
        </div>
     );
}
 
export default Merch;