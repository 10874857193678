import Footer from "./Footer";
import Navbar from "./Navbar";
import graduate from "./imgs/graduate.jpg";
import std from "./imgs/std3.png";
import student from "./imgs/std6-removebg-preview.png";
import Counter from "./components/Counter";
import CoursesLists from "./components/CoursesLists";
import ReadMore from "./components/ReadMore";
import { useState } from "react";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { waveform } from 'ldrs';
import parulImg from "./imgs/parul.jpg";
import ganpat from "./imgs/ganpat.jpg";
import amity from './imgs/amity.png';
import meg from './imgs/meg.png';
import { Helmet } from 'react-helmet';


const Internship = () => { 
const ammit_courses = ['Applied Sciences',
        'Architecture',
        'Biotech',
        'Commerce',
        'Computer Science IT',
        'Design',
        'Earth & Environmental Sciences',
        'Economics',
        'Engineering',
        'English literature',
       ' Food Technology',
        'Geo Informatics & Remote Sensing',
        'Health & Allied Sciences',
        'Health Sciences',
       ' Interior Design',
        'Journalism & Mass Communication',
        'Law',
        'Management',
        'Medical',
       ' Microbial Sciences',
        'Molecular Medicine & Stem Cell Research and Cancer Biology',
        'Molecular Medicine & Stem Cell Research and Cancer Biology',
        'Optometry & Vision Sciences',
        'Pharmacy',
        'Psychology & Behavioural Science'
        ]
const parul = [
            {
              category: "B.Tech.",
              items: [
                "B.Tech. - Agricultural Engineering",
                "B.Tech. - Automobile Engineering",
                "B.Tech. - Bio-Medical Engineering", 
                "B.Tech. - Electronics and Communication Engineering",
                "B.Tech. - Food Technology",
                "B.Tech. - Mechanical Engineering", 
                "B.Tech. - Computer Science and Engineering with Cloud Computing",
                "B.Tech. - Computer Science and Engineering with Cyber Security",
                "B.Tech. - Computer Science and Engineering with Internet of Things",
                "B.Tech. - Aeronautical"
              ]
            },
            {
              category: "B.Sc.",
              items: [
                "B.Sc. - Hotel Management",
                "B.Sc. - Tourism and Travel Management",
                "B.Sc. - Biotechnology",
                "B.Sc. - Biochemistry",
                "B.Sc. - Chemistry",
                "B.Sc. - Environmental Science",
                "B.Sc. - Genetics",
                "B.Sc. - Geology", 
                "B.Sc. - Film and TV Production",
                "B.Sc. - Public Health",
                "B.Sc. - Gaming Technology",
                "B.Sc. - Pharmaceutical Chemistry",
                "B.Sc. - Hotel Management - Tourism and Travel Management"
              ]
            },
            
        ]
const phdSpecializations = [
            "Ph.D. (Petrochemical Engineering specialization)",
            "Ph.D (Chemistry)",
            "Ph.D (Civil Engineering)",
            "Ph.D (Commerce)",
            "Ph.D (Computer Applications)",
            "Ph.D (Computer Sciences or Information Technology)",
            "Ph.D (Electrical Engineering)",
            "Ph.D (English)",
            "Ph.D (Management)",
            "Ph.D (Mathematics)",
            "Ph.D (Mechanical Engineering)",
            "Ph.D (Pharmacy)",
            "Ph.D (Physics)",
            "Ph.D. (Botany)",
            "Ph.D (Biotechnology)",
            "Ph.D (Electronics)",
            "Ph.D (Microbiology)",
            "Ph.D. (Soil Water Conservation Engineering)"
        ];

        waveform.register();

const [firstname, setFirstname] = useState('');
const [lastname, setLastname] = useState('');
const [email, setEmail] = useState('');
const [contact, setContact] = useState('');
const [country, setCountry] = useState('');
const [program, setProgram] = useState('');
const [isLoading, setIsLoading] = useState(false);


const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = new FormData();
    data.append('firstname',firstname);
    data.append('lastname', lastname);
    data.append('email',email);
    data.append('contact', contact);
    data.append('country', country);
    data.append('program', program);
    data.append('save', true);
    
    try {
        const response = await axios.post('https://api.campus360recreations.com/scholarships/submit_request/', data);
            if(response.data.status !=0){
                toast.success('Thanks! 360 will get back to you soon!');
                setFirstname('');
                setLastname('');
                setEmail('');
                setCountry('');
                setProgram('');
                setContact('');
            }else{
                throw new Error('Something went wrong');
            }
            setIsLoading(false);

    } catch(e){
        toast.error(e.message);
        setIsLoading(false);

    }
}   
        
return ( 
        <div>
            <Helmet>
                <title>Campus 360 Scholarhips</title>
                <meta name="description" content="Campus 360 partners with various universities and organizations to offer students opportunities for both local and international scholarships, helping them to achieve their academic dreams." />  
            </Helmet>
            <div><Toaster/></div>
        <div className="scholar bg-cover bg-bottom relative" style={{ backgroundImage: `url(${graduate})` }}>
            <div className="absolute w-full h-full opacity-80 bg-black"></div>
            <div className="nav relative">
                <Navbar />
            </div> 
            <div className="md:mx-10 relative mt-5 px-7 text-[white] flex align-center justify-center mb-10" >
                <div className="">
                    <div className="semi-bold  grow">
                        <h1 className="md:text-7xl md:my-3 mg:leading-loose leading-tight text-4xl">Free <span className="text-[#BF3B7D] font-bold">Scholarships</span> </h1>
                        <h1 className="md:text-7xl md:my-3 mg:leading-loose leading-tight text-4xl">For Every Bright </h1>
                        <h1 className="md:text-5xl md:my-3 mg:leading-loose leading-tight text-4xl">Student In <span className="text-[#BF3B7D] font-bold">Uganda</span> </h1>
                        <button className="button md:py-3 md:px-5 px-3 py-2 text-sm md:text-normal mb-3 md:w-1/4 rounded-full mt-6"
                        type="button"
                        data-twe-offcanvas-toggle
                        data-twe-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                        data-twe-ripple-init
                        data-twe-ripple-color="light">Enquire Now</button>
                    </div> 
                </div>
                <div className="hidden md:block relative oval ">
                        <img src={std} className="relative" alt="No img" />
                    </div>
            </div> 
            </div>
            <div className="container w-full px-5 md:mx-auto  mt-4">
                <div className="flex items-center gap-x-4 justify-around">
                    <div className="bub">
                        <img src={student} alt="" />
                    </div>
                    <div className="text-white">
                        <h1 className="md:text-4xl font-thin md:my-5tracking-wide"><span className="text-[#BF3B7D] text-3xl md:text-5xl">Hold On,</span> It Gets Better!</h1>
                        <h1 className="md:text-4xl font-thin md:my-5tracking-wide">Take Your Education to New Heights and</h1>
                        <h1 className="md:text-4xl font-thin md:my-5tracking-wide">Propel Your Education Forward With Our</h1>
                        <h1 className="md:text-4xl  my-5 tracking-wide font-bold">Empowering Scholarship Programs</h1>

                        <div className="border-4 border-[#BF3B7D] flex md:mt-3 md:p-7">
                            <h1 className="inline text-[#BF3B7D] text-3xl my-auto mr-2 md:text-9xl"><Counter /></h1>
                            <h1 className="inline md:text-5xl"><span className="text-[#BF3B7D]">Scholarship</span>
                                <br />
                            On Tuition Fees
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container md:px-20 my-10 md:my-20 mx-auto text-center text-white md:text-2xl border-b-2 border-[#BF3B7D] pb-10">
                <h1 className="md:text-4xl leading-loose  mb-5"><span className="text-[#BF3B7D] text-3xl m">Campus360 Scholarships:</span> Empowering Education</h1>
                <p className="leading-relaxed mx-3">Every bright student like you needs bright education , that’s why we strive to provide educational opportunities for students facing financial hardships. Our scholarships offer tuition assistance and support for students aiming to study both locally and abroad. By partnering with prestigious universities, we open doors to global education and provide holistic support to ensure student success. Join us in our mission to make education accessible for all and create a brighter future, one student at a time.</p>
            </div>

            <div className="container mx-auto text-white">
            <div className="h-full min-h-screen w-full text-black md:pt-12 p-4">
                <div className="grid   md:grid-cols-2 md:gap-14">
                    <div className="rounded-xl hover:border-2 transition-all hover:shadow-2xl mt-10 cursor-pointer hover:shadow-[#BF3B7D] ease-in-out border-[#BF3B7D] bg-black p-6 text-center shadow-xl">
                    <div className="mx-auto flex h-16 w-16 -translate-y-12 transform items-center justify-center rounded-full bg-teal-400 shadow-lg shadow-teal-500/40">
                        <img src={meg} className="rounded-full w-full h-full" alt="" />
                    </div>
                    <h1 className="text-white mb-3 text-xl font-medium lg:px-14">DATTA MECHE INSTITUTE OF HIGHER EDUCATION & RESEARCH</h1>
                        <ReadMore text="Datta Meghe Institute of Higher Education & Research (Deemed to be University) is located in a 165 acre campus in Sawangi (Meghe), Wardha about 80 kms from Nagpur, Maharashtra, India which is the geographic centre of India. It is located away from the hustle and bustle of the city amidst serene surroundings in a self sustaining complex with an excellent academic ambience." />
                    </div>
                    <div data-aos-delay="150" className="rounded-xl hover:border-2 transition-all hover:shadow-2xl mt-10 cursor-pointer hover:shadow-[#BF3B7D] ease-in-out border-[#BF3B7D] bg-black p-6 text-center shadow-xl">
                    <div className="mx-auto flex h-16 w-16 -translate-y-12 transform items-center justify-center rounded-full shadow-lg bg-rose-500 shadow-rose-500/40">
                        <img src={amity} className="rounded-full w-full h-full" alt="" /> 
                    </div>
                    <h1 className="text-white mb-3 text-xl font-medium lg:px-14">AMITY UNIVERSITY</h1>
                        <ReadMore text="AMITY UNIVERSITY PUNJAB Amity University in Mohali (Punjab) is the eleventh university to be established by the not-for-profit Amity Foundation. Being set up as a multi-disciplinary university, it offers UG & PG Programmes in over 25 disciplines. Located on a 40 acre campus just 10 minutes away from the Chandigarh International Airport, it is proposed to be the hub of hi-end research, with extensive collaborations with top global universities to facilitate research opportunities for faculty and international exposure for its students." />
                    </div>
                    <div data-aos-delay="300" className="rounded-xl hover:border-2 transition-all hover:shadow-2xl mt-10 cursor-pointer hover:shadow-[#BF3B7D] ease-in-out border-[#BF3B7D] bg-black p-6 text-center shadow-xl">
                    <div className="mx-auto flex h-16 w-16 -translate-y-12 transform items-center justify-center rounded-full shadow-lg bg-indigo-500 shadow-indigo-500/40">
                        <img src={parulImg} className="rounded-full w-full h-full" alt="" /> 
                    </div>
                    <h1 className="text-white mb-3 text-xl font-medium lg:px-14">PARUL UNIVERSITY</h1>
                    <ReadMore text="Here You Can Join 3,500+ International Students
                            From 75+ Nationalities and a unique exposure to
                            85+ International Professors
                            Your International Education
                            From A Leading International University
                            At the heart of Vadodara, Gujarat lies Parul University a multidisciplinary destination of learning and innovation, propelling quality in higher education through extensive research, a global campus diversity, a unique learning exposure and a record of being India’s Best Private University and the Youngest Private University to receive NAAC A++ accreditation." />
                    </div>
                    <div data-aos-delay="300" className="rounded-xl hover:border-2 transition-all hover:shadow-2xl mt-10 cursor-pointer hover:shadow-[#BF3B7D] ease-in-out border-[#BF3B7D] bg-black p-6 text-center shadow-xl">
                    <div className="mx-auto flex h-16 w-16 -translate-y-12 transform items-center justify-center rounded-full shadow-lg bg-indigo-500 shadow-indigo-500/40">
                        <img src={ganpat} className="rounded-full w-full h-full" alt="" />  
                    </div>
                    <h1 className="text-white mb-3 text-xl font-medium lg:px-14">GANPAT UNIVERSITY</h1>
                    <ReadMore text="Ganpat University (GUNI) is a private university in Kherva, Mehsana in the state of Gujarat, India. It was established in 2005 through the State Legislative act no 19 of 2005, Government of Gujarat, and recognized by the UGC under the section 2(f) of the UGC Act, 1956 having campus spread over more than 300 acres of land and more than 10,000 students on campus." />
                    </div>
                </div>
                </div>
            </div>

            <div className="container mx-auto md:mt-20 mt-10 text-white">
                <h1 className="md:text-4xl text-2xl text-center mb-10">Recognized Diploma, Bachelor & Master's Programs</h1>
                <div className="conatainer mx-auto grid gap-x-5 md:grid-cols-3 grid-cols-2 px-3">
                <div>
                    <h1 className="md:text-3xl text-xl mb-2">Amity University</h1>
                    <CoursesLists list={ammit_courses} />
                </div>
                <div>
                    <h1 className="md:text-3xl text-xl mb-2">Parul University</h1>
                    {parul.map((data, index) => (
                        <div key={index}>
                            <h1>{data.category}</h1>
                            <CoursesLists list={data.items} />
                        </div>
                    ))}
                </div>
                 
                <div>
                    <h1 className="md:text-3xl text-xl mb-2">GanPat University</h1>
                    <CoursesLists list={phdSpecializations} />
                </div>
                </div>
            </div> 

            <div
        className="invisible fixed bottom-0 right-0 border-s-4 border-[#BF3B7D] z-[1045] flex w-96 max-w-full h-3/4 translate-x-full flex-col  bg-white/2 backdrop-blur-md bg-clip-padding text-neutral-700 shadow-sm outline-none transition duration-300 ease-in-out data-[twe-offcanvas-show]:transform-none dark:bg-body-dark dark:text-white"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        data-twe-offcanvas-init>
        <div className="flex items-center justify-between p-4">
            <h5
            className="mb-0 font-semibold leading-normal md:text-2xl"
            id="offcanvasRightLabel">
            Enquire Now
            </h5>
            <button
            type="button"
            className="box-content rounded-none border-none text-neutral-500 hover:text-neutral-800 hover:no-underline focus:text-neutral-800 focus:opacity-100 focus:shadow-none focus:outline-none dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
            data-twe-offcanvas-dismiss
            aria-label="Close">
            <span className="[&>svg]:h-6 [&>svg]:w-6">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12" />
                </svg>
            </span>
            </button>
        </div>
            <div className="offcanvas-body text-black flex-grow overflow-y-auto p-4">
                <form  onSubmit={handleSubmit} className="max-w-md mx-auto">
                <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-5 group">
                        <input type="text" onChange={(e) => setFirstname(e.target.value)} className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#BF3B7D] peer" placeholder=" " required />
                        <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#BF3B7D]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First name</label>
                    </div>
                    <div className="relative z-0 w-full mb-5 group">
                        <input type="text"onChange={(e) => setLastname(e.target.value)}  className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#BF3B7D] peer" placeholder=" " required />
                        <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#BF3B7D]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Last name</label>
                    </div>
                </div> 
                <div className="relative z-0 w-full mb-5 group">
                    <input type="email" onChange={(e) => setEmail(e.target.value)} className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#BF3B7D] peer" placeholder=" " required />
                    <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#BF3B7D]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
                </div>
                
                <div className="relative z-0 w-full mb-5 group">
                    <input type="number" onChange={(e) => setContact(e.target.value)} className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#BF3B7D] peer" placeholder=" " required />
                    <label  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#BF3B7D]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Phone Number</label>
                </div>
                <div className="relative z-0 w-full mb-5 group">
                    <select onChange={(e) => setCountry(e.target.value)} className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#BF3B7D] peer" placeholder=" " required>
                        <option >--Select Country</option>
                        <option value="Uganda" className="text-black">Uganda</option>
                        <option value="Kenya" className="text-black">Kenya</option>
                        <option value="Tanzania" className="text-black">Tanzania</option>
                        <option value="Rwanda" className="text-black">Rwanda</option>
                        <option value="Burundi" className="text-black">Burundi</option>
                    </select>
                </div>
                <div className="relative z-0 w-full mb-5 group">
                    <select onChange={(e) => setProgram(e.target.value)} className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#BF3B7D] peer" placeholder=" " required>
                        <option className="text-black">--Select Program</option>
                        <option value="Diploma"className="text-black">Diploma</option>
                        <option value="Bacholor"className="text-black">Bacholor</option>
                        <option value="Master"className="text-black">Master</option>
                    </select>
                </div>
                
                <button type="submit"  className="button py-3 px-5 w-full "> {isLoading ?
                        <l-waveform
                        size="25"
                        stroke="3.5"
                        speed="1" 
                        color="white" 
                        ></l-waveform> : 'Submit' }
                        </button>
                </form> 
            </div>
            </div>
            
            <Footer />
        </div>
     );
}
 
export default Internship;