import Navbar from "./Navbar";
import Footer from "./Footer";  
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast"; 
import AudioPlayer from "./AudioPlayer";
import { dotPulse } from 'ldrs'
import moment from 'moment-timezone'; 
import {  FaHeart  } from "react-icons/fa"; 
import { ring } from 'ldrs'
import FormatLikes from "./components/FormatLikes";
import { FaPlayCircle } from 'react-icons/fa';

const SpaceWatch = () => {
    const { id } = useParams(); 
    const [audio, setAudio] = useState(null);
    const [spaces, setSpaces] = useState([]);
    const [comment, setInputs] = useState({user_comment: ''}); 
    const [comments, setComments] = useState([]);     
    const [isLoading, setIsLoading] = useState(false); 
    const [isChecked, setIschecked] = useState(false);
    const [isLiked ,setIsliked] = useState(0);
    const [likes, setLikes] = useState(0);
    const [visibleCount, setVisibleCount] = useState(5);

    // Function to handle the "Load More" button click
    const loadMoreSpaces = () => {
        setVisibleCount(prevCount => prevCount + 5); // Increase the count by 5
    };

    dotPulse.register();
    ring.register();
    const fetchSpaces = async () => {
        try{
            const response = await axios.get('https://api.campus360recreations.com/spaces/'); 
            setSpaces(response.data); 
            const newVid = response.data.find(audio => audio.id == id);
            setAudio(newVid);  
            setLikes(newVid.likes);
        } catch(error) {
            console.log('an error occured'+ error);
        }
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const comment = event.target.value;
        setInputs({[name]: comment});
    }

    const calculateTimeSinceUploadOne = (uploadTime) => {
        const targetDateTime = uploadTime;
        const timeZone = 'Africa/Kampala';
     
        const targetMoment = moment.tz(targetDateTime, timeZone); 
        const now = moment.tz(timeZone);
        let timenow = '';
        const difference = now.diff(targetMoment, 'seconds');
        if (difference < 60) {
            timenow = 'Just Now';
        } else if (difference < 3600) {
            const minutes = Math.floor(difference / 60);
            timenow = minutes + ' Minutes Ago';
        } else if (difference < 86400) {
            const hours = Math.floor(difference / 3600);
            timenow = hours + ' Hours Ago';
        } else {
            const days = Math.floor(difference / 86400);
            timenow = days + ' Days Ago';
        }
    
        return timenow;
    };
     

      const getComments = () => {
        axios.get('https://api.campus360recreations.com/comments/space/'+ id)
        .then((response) =>  { 
                 setComments(response.data);
             }).catch((error) => {
                 console.log('an error occured'+ error);
             });

    }

    

      const submitComment = (e) => {
        e.preventDefault();
        let userId = localStorage.getItem('user');    
        if(userId){
            let comm = comment.user_comment.trim();

            if (comm!= ''){
            setIsLoading(true);
            comment['s_id'] = id;
            comment['user_id'] = userId;
            //console.log(comment);
            axios.post('https://api.campus360recreations.com/comment/space/save', comment)
            .then(function(response) {   
                if(response.data.status == 1){
                    toast.success('Thanks for commenting!'); 
                    getComments();
                    setInputs({user_comment: ''}); 
                }
                setIsLoading(false);
                
            }).catch((e) => {
                toast.error(e.message);
                setIsLoading(false);
            }); 
          }
        }else{
            toast.error('Sign In to comment');
        }
    }
     

      useEffect(() => {
        getComments(); 
        fetchSpaces(); 
    }, [id]);  


    const handleLike = () => {
        let userId = localStorage.getItem('user');
        if(userId){
            let likevid = {
                'space_id': id,
                'user_id': userId,
            }
            axios.post('https://api.campus360recreations.com/like/space/', likevid)
            .then(function(response) {    
                if(response.data.status == 1){ 
                    checkLiked();
                }  
            }).catch((e) => {
                toast.error(e.message); 
            }); 
            
        }else{
            toast.error('Sign In to Like Space');
        }
         
        
    }

    const checkLiked = () => {
        setIschecked(true);
        let userId = localStorage.getItem('user');
        let likevid = {
            'space_id': id,
            'user_id': userId,
        }
        axios.post('https://api.campus360recreations.com/liked/space/', likevid)
            .then(function(response) {   
                if(response.data.status == 1){  
                    if(response.data.result === 1){
                        setIsliked(1);
                        setLikes((prevLikes) => prevLikes + 1);
                    }else{ 
                        setIsliked(0);
                        setLikes((prevLikes) => (prevLikes > 0 ? prevLikes - 1 : prevLikes));

                    }
                    setIschecked(false);
                }else{
                    throw new Error('Something Went Wrong!');
                 } 
                
            }).catch((e) => {
                setIschecked(false);
                toast.error(e.message); 
            }); 
    }


    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        }); 
        }

    return ( 
        <div className="watch">
            <div><Toaster/></div>
            <div className="nav relative">
                <Navbar navlinks='show' />
            </div>
            <div className="container px-4  mx-auto text-white my-12">
                <div className="md:flex">
                    {audio ? ( 
                        <div className="pl-6 grow mr-5">
                            <div className=" md:relative">
                                <AudioPlayer className="w-full " id={id} source={'https://api.campus360recreations.com/uploads/spaces/' + audio.audio } thumbnail = {audio.thumbnail} />
                            </div>
                         <div className="notification flex items-center mt-3 md:mt-10 md:pt-5">
                            <img src={audio.thumbnail} alt="no image" />
                            <h1 className="text-xl md:text-3xl ms-4">{audio.title}</h1>
                            <div className="ml-auto text-center">
                            {isChecked ? (
                                <l-ring size="20" stroke="1" bgCapacity="0" speed="2" color="white"></l-ring>
                               
                            ) : (
                                isLiked === 1 ? (
                                    <div>
                                        <FaHeart onClick={handleLike} className="text-xl md:text-5xl text-red-600 cursor-pointer" />
                                        <span className="text-sm"><FormatLikes className="mr-5" likes={likes} /></span>
                                    </div>
                                    ) : (
                                    <div>
                                        <FaHeart onClick={handleLike} className="text-xl md:text-5xl cursor-pointer" />
                                        <span className="text-sm"><FormatLikes className="mr-5" likes={likes} /></span>
                                    </div>
                                    )
                            )}
                            </div>
                        </div> 
                        <div className="description mt-2 md:mt-7 p-2 md:p-5 rounded-xl">
                            <h2 className="font-bold mb-5 flex"><FormatLikes className="mr-5" likes={audio.views} /> <span className="mx-3">views</span>  {calculateTimeSinceUploadOne(audio.created_at) }  <span>#No.3 Trending</span> </h2>
                            <p className="text-sm md:text-lg">{audio.description}</p>
                            <span className="mt-7">Show More</span>
                        </div>
                        <div className="comments mt-5">
                            <h1 className="text-2xl mb-3 font-bold">{comments.length} comments</h1>
                            <input type="text" name="user_comment" value={comment.user_comment } onChange={handleChange} className=" pl-2 w-full rounded-lg" placeholder="Write a comment..." />
                            <button onClick={submitComment} disabled={isLoading} className="block button ms-auto mt-4 p-2 text-black rounded">
                                {isLoading ? 
                                <l-dot-pulse
                                  size="33"
                                  speed="1.3" 
                                  color="white" 
                                ></l-dot-pulse> : 'Comment'
                            } 
                            </button> 
                            <div className="comment-flow">
                                 {comments.map((comment) => (
                                    <div className="flex my-4 md:my-7" key={comment.id}>
                                    <img src={comment.img} alt="" />
                                    <div className="ml-3">
                                        <div className="flex">
                                            <h3 className="semi-bold mr-6 text-lg">{comment.username}</h3>
                                            <span className="text-sm">{calculateTimeSinceUploadOne(comment.created_at)}</span>
                                        </div>
                                        <p>{comment.s_comments}</p>
                                    </div>
                                </div>
                                 ))}
                            </div>
                        </div>
                    </div>
                    ): (
                        <div>
                            
                        <div role="status" className="w-full p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700">
                        <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                            <div className="grid h-full max-h-[300px] min-h-[160px] w-full max-w-xs animate-pulse place-items-center rounded-lg bg-gray-300">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="h-12 w-12 text-gray-500"
                                >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                                />
                                </svg>
                            </div>
                        </div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                        <div className="flex items-center mt-4">
                            <svg className="w-10 h-10 me-3 text-gray-200 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                            </svg>
                            <div>
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                                <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                            </div>
                        </div>
                        <span className="sr-only">Loading...</span>
                        </div>

                            
                        </div>
                    ) }
                    <div className="recommend rounded-xl mx-auto mt-2 p-3">
                        <h3 className="border-b-2 border-grey">Recommended <span className="font-bold ml-10">More News</span></h3>
                       {spaces.length > 0 ? (
                <>
                    {spaces.slice(0, visibleCount).map((space) => (
                        <div className="thumb mt-3" key={space.id}>
                            <Link onClick={scrollToTop} to={`/spaces/watch/${space.id}`}>
                                <div className="thumbnail relative">
                                    <img src={space.thumbnail} alt="" />
                                    <FaPlayCircle className="absolute top-1/2 left-1/2 z-60 text-2xl" />
                                </div>
                            </Link>
                            <div className="thumb-footer mt-7 flex">
                                <img src={space.thumbnail} alt="no thumb" />
                                <p className="ml-4 my-auto md:text-lg">{space.description.slice(0,50)}...</p> 
                            </div>
                        </div>
                    ))}
                    {visibleCount < spaces.length && (
                        <button 
                            className="load-more-btn mt-4 px-4 py-2 bg-[#BD3B7C] text-white rounded"
                            onClick={loadMoreSpaces}
                        >
                            Load More
                        </button>
                    )}
                </>
            ) : (
                <p>No spaces available</p>
            )}
                         
                    </div>
                </div>
            </div>
            <Footer />
        </div>

     );
}
 
export default SpaceWatch;