import Navbar from "./Navbar";
import heroimage from './imgs/IMG_87075.jpg';
import pcimage from './imgs/Capture-removebg-preview.png';
import Footer from "./Footer";
import { ReactTyped } from "react-typed";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';


import img1 from "./homeImages/img1.jpg"
import img2 from "./homeImages/img2.jpg"
import img3 from "./homeImages/img3.jpg"
import img4 from "./homeImages/img4.jpg"
import img5 from "./homeImages/img5.jpg"

const Home = () => {

  const imgs = [img1,img2, img3, img4,img5,img1,img2, img3, img4,img5];
    return ( 
        <div>
          <Helmet>
                <title>Campus 360</title>
                <meta name="description" content="Campus 360 is a dynamic and evolving player in Uganda's media and entertainment sector. Our roots are deeply embedded in the belief that media and content have the power to shape perceptions, foster knowledge, and engage communities" />  
            </Helmet>
            <div className="hero">
            <Navbar /> 
            <img src={heroimage} alt="" className="hero-bg" />
            <div className="conatiner hero-content md:ml-12">
                <h1 className="text-white text-2xl md:text-7xl font-bold">Unlimited
                <ReactTyped
                      strings={["Campus news,", "Fun stories," , "Tech updates," ,"Fashion trends"]}
                      typeSpeed={120}
                      loop
                      backSpeed={50}
                      cursorChar="|"
                      showCursor={false}
                      className="ml-2 md:ml-4"
                    />
                </h1>
                 
                <h1 className="text-white text-2xl md:text-7xl font-bold leading-relaxed">games and more.</h1>
                <p className="mt-9 text-white md:text-2xl">Ready to dive into the 360 Campus experience?</p>
                <div className="address flex mt-5">
                  <input type="email" placeholder="Enter Address" className="px-4 py-2  w-1/2 rounded border focus:outline-none " />
                  <Link to='/signup' className="button w-1/3 text-center rounded-lg block">Get Started</Link>
                </div>
            </div>
            </div>
            
        <div className="image-container my-12 px-3 md:my-10 mx-auto">
          <div className="text-white text-center mb-12">
            <h1 className="md:text-3xl text-xl font-bold">Join the Coolest Campus and youth Community</h1>
            <p>Get the latest trends and insights from your university.</p>
          </div> 

          <div className="relative flex flex-col justify-center overflow-hidden">
            <div className="absolute inset-0"></div>
            <div className="pointer-events-none relative flex gap-5 overflow-hidden">
              <div className="animate-marquee flex min-w-full shrink-0 items-center justify-around gap-10">
                {imgs.map((img) => (
                        <img key={img + Math.random()} className="aspect-square max-w-[clamp(10rem,28vmin,20rem)] rounded-md object-cover shadow-md" src={img} alt="" />
                ))}
              </div>
              <div aria-hidden="true" className="animate-marquee flex min-w-full shrink-0 items-center justify-around gap-10">
              {imgs.map((img) => (
                        <img key={img + Math.random() } className="aspect-square max-w-[clamp(10rem,28vmin,20rem)] rounded-md object-cover shadow-md" src={img} alt="" />
                ))}    </div>
            </div> 
          </div>

        </div>

        <div className="games mx-auto text-white container mt-5 p-10 md:p-20 rounded-xl">
          <div className="container md:pb-10 border-b-4 border-orange-900 text-center">
            <h1 className="text-2xl md:text-4xl">Want the best campus games?</h1>
            <p className="md:text-xl my-4 md:my-8">Enjoy immersing games, campus news, tech updates <br /> and more. </p>
            <Link to="/signup" className="button my-3 w-1/2 mx-auto md:py-2 rounded-xl">Create Account</Link>
          </div>
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 items-center">
              <div>
                <img src={pcimage} alt="no image" />
              </div>
              <div className="text-center ">
                <h1 className="text-3xl md:text-5xl">Watch Anywhere. Anytime</h1>
                <p className="md:text-xl mt-1 leading-relaxed">Gain full control of your watchtime experience. Watch all your favourite shows and be part of the cool campus life just on your phone, tablet and laptop. </p>
              </div>
            </div>
          </div>
        </div>  
        <div className="container pl-10 pt-10 pb-20">
          <p className="mt-9 text-white text-lg md:text-2xl">Ready to dive into the 360 Campus experience?</p>
          <div className="address flex mt-5">
            <input type="email" placeholder="Enter Address" className="px-4 py-2  w-1/2 rounded border focus:outline-none " />
            <Link to="/signup" className="button w-1/3 text-center rounded-lg block ">Get Started</Link>
          </div>
        </div>

        <Footer />
        </div>

        
    );
}
 
export default Home;