 // ResetPassword.js
import React, { useState } from 'react';
import axios from 'axios';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Toaster, toast } from 'react-hot-toast'; 
import heroimage from '../imgs/IMG_87075.jpg'; 
import { waveform } from 'ldrs';  
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false); 
  const nav  = useNavigate();
  waveform.register();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
       setIsLoading(true);
      const response = await axios.post('https://api.campus360recreations.com/reset_password/password/', { token, password });
       
      if(response.data.status != 0){
            toast.success(response.data.message );
            setIsLoading(false);
            setTimeout(() => {
                nav('/login');
            }, 1000);
       }else{
        throw new Error(response.data.message);
       }
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="text-white">  
    <Toaster /> 
    <div className="flex backdrop-blur-sm bg-black/30 cont bg-cover bg-no-repeat bg-blend-multiply bg-center justify-center" style={{minHeight: '100vh', backgroundImage: `url(${heroimage})`}}>
      <Navbar /> 
      <div className="py-7 content w-3/4 md:mt-30 mx-2 my-auto md:py-10 px-4 rounded-xl font-bold text-center">
        <h1 className="primary text-2xl lg:text-5xl">Recover Password</h1>
        <form onSubmit={handleSubmit} className="mx-3">
          <div className="flex flex-col items-center">
          <input onChange={(e) => setToken(e.target.value)} value={token} type="number" autoComplete="on" className="rounded mx-auto mt-4 font-normal py-4 w-full md:w-2/3 px-3 focus:outline-none" placeholder="Enter Reset Token" />
          <input onChange={(e) => setPassword(e.target.value)} value={password} type="password" autoComplete="on" className="rounded mx-auto mt-4 font-normal py-4 w-full md:w-2/3 px-3 focus:outline-none" placeholder="New Password" />
                <button type="submit" disabled={isLoading} className="button rounded-xl block w-1/2 mt-5 text-sm md:text-normal mx-auto py-2">
                {isLoading ? (
                  <l-waveform size="25" stroke="3.5" speed="1" color="white"></l-waveform>
                ) : (
                  'Change Password'
                )}
              </button> 
            
            <div className="my-4">
            
          </div>
          </div>
        </form>
      </div>
    </div>
    <Footer className="mt-auto" /> 
  </div>  
  );
};

export default ResetPassword;
