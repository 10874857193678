import Navbar from "./Navbar";
import Footer from "./Footer";
import SideBar from "./SideBar";
import { Helmet } from 'react-helmet';


const About = () => {
    return ( 
        <div className="about">
            <Helmet>
                <title>About 360</title>
                <meta name="description" content="This is the new generational youth platform dedicated to enriching the lives of university students offering a wide range of content and services. This includes educational resources, trends, lifestyle, news, tech, entertainment, and more, all aimed at empowering students and creating a vibrant campus culture." /> 
                <meta name="keywords" content="about 360, about campus360 , campus360 about"></meta>
            </Helmet>
            <div className="nav relative">
                <Navbar page='About' />
            </div>
            <div className="container mx-auto mt-10 text-white">
              <div className="flex">
                <div className="hidden md:block">
                    <SideBar />
                </div>
                <div className="cont rounded-xl p-8 mb-8 md:ml-10 w-full">
                    <h3 className="primary text-lg md:text-2xl">Our mission</h3>
                    <p>At Campus 360, we are on a mission to transform the landscape of higher education in Uganda. We believe that higher education is not a luxury but a fundamental requirement for success in Uganda's formal job market. Our primary mission is to empower students with knowledge, skills, and support that will see them through their academic journey and prepare them for a competitive employment sector</p>
                    <h3 className="primary text-lg md:text-2xl mt-5">Our vision</h3>
                    <p>Our vision is to become the beacon of hope for campus students, parents, and all affiliates with higher education in Uganda. We envision a future where every student has access to quality education, and higher education is perceived as a path to career success, not just a luxury.</p>
                    <h3 className="primary text-lg md:text-2xl mt-5">Who we are</h3>
                    <p>Campus 360 is a dynamic and evolving player in Uganda's media and entertainment sector. Our roots are deeply embedded in the belief that media and content have the power to shape perceptions, foster knowledge, and engage communities.</p>
                    <h3 className="primary text-lg md:text-2xl mt-5">Our content</h3>
                    <p>We offer a wide range of engaging and informative content designed to enhance the campus life experience. Our content includes news updates, technology insights, fashion polls, opinion pieces, podcasts, games, and much more. We believe that by providing value and reducing time wastage on irrelevant social media, we can make a significant impact.</p>
                    <h3 className="primary text-lg md:text-2xl mt-5">Our Approach</h3>
                    <p>1. Skill Development: We offer diverse training programs that equip young people with skills in fields like videography, fashion, content creation, and more, enhancing their employability.<br />
                    2. Engaging Content: We strive to minimize time wastage on irrelevant social media by providing engaging and educational content that students love. <br />
                    3. Tangible Impact: Our impact is evident through our growing viewership, engagement rates, and collaborations within Ugandan universities. We have over 7,000 followers on platforms like Instagram and TikTok and maintain an impressive 72% engagement rate within Ugandan universities.</p>
                    <h3 className="primary text-lg md:text-2xl mt-5">Our Team</h3>
                    <p>Our team consists of skilled and diverse creatives in fields like graphic design, videography, photography, video editing, and more. We are passionate about our mission and committed to making a difference. Campus 360 is not just a company; it's a movement to redefine the perception of higher education and provide students with the tools they need to succeed. Join us on this exciting journey towards a brighter future for Uganda's youth.</p>
                 </div>
              </div>
            </div>
            <Footer />
        </div>
     );
}
 
export default About;