import Navbar from "./Navbar";
import Footer from "./Footer";
import heroimage from './imgs/IMG_87075.jpg';
import { Link } from "react-router-dom";
import { useState ,useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import { waveform } from 'ldrs';
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';


const SignUp = () => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const googleLogin = async (email) => {
        try {
            setIsLoading(true);
            const response = await axios.post('https://api.campus360recreations.com/user/google_login/', 
            {'email': email}); 
            if (response.data.status !== 0) { 
              toast.success('Logging In...');
              setTimeout(() => {
                localStorage.setItem("login", true); 
                localStorage.setItem("user", response.data.user);
                navigate('/videos');
                setIsLoading(false);
              }, 1000); 
            }
            else{
                throw new Error("Something Went Wrong!");
            }
        } catch (e){
          toast.error(e.message);
          setIsLoading(false);
        }
      
       }
 
    const validateEmail = (email) => { 
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      }; 
      waveform.register(); 
      const googleSignIn = async (data) => { 
        try {
            setIsLoading(true);
            const response = await axios.post('https://api.campus360recreations.com/user/google_signup/', 
            data);
            if (response.data.status !== 0) {  
                googleLogin(data.email);
            }else{
                throw new Error(response.data.message);
            }
        } catch (e){
          toast.error(e.message);
          setIsLoading(false);
        }
      
       }

      async function verifyToken(token) {
        try {
           const { data } = await axios.get(`https://oauth2.googleapis.com/tokeninfo?id_token=${token}`) 
          googleSignIn(data);
        }catch(e) {
          toast.error(e.message);
        }
     } 


      const responseMessage = (response) => { 
        const accessToken = response?.credential; 
        if (accessToken) {
          verifyToken(accessToken)  
        }
    };

    const errorMessage = (error) => {
        console.log(error);
    };
    
    useEffect(() => {
        let login = localStorage.getItem('login');
        if (login) {
          navigate('/videos');
        }
        let loginstatus = localStorage.getItem('loginstatus');
        if (loginstatus) {
          toast.success(loginstatus);
          setTimeout(() => {
            localStorage.clear();
          }, 3000);
        }
      }, [navigate]);

const handleSubmit = (e) =>{
    e.preventDefault();
    if (!validateEmail(email)) {
        toast.error("Invalid email address");
        return;
      }

    if (password !== confirmPassword) {
        toast.error("passwords Donot match!");
        setConfirmPassword('');
        return;
      }
      if (password.length < 5){
            toast.error('Password is weak!');
        return;
      }

      const data = new FormData();
      data.append('username', username);
      data.append('email', email);
      data.append('password', password); 
      setIsLoading(true);

        axios.post('https://api.campus360recreations.com/users/register/', data)
        .then((response) => {
        if(response.data.status == 1){
            toast.success('Verification Email Sent!');
            setConfirmPassword('');
            setEmail('');
            setPassword('');
            setUsername('');
            setIsLoading(false);

        }else{
            toast.error(response.data.message);
            setIsLoading(false);
        }
    })
    .catch((e) => {
        toast(e.data);
    })
}



    return ( 
        <div className="signup text-white"> 
        <Helmet>
                <title>SignUp</title>
                <meta name="description" content="Join the Campus 360 community by signing in to access exclusive content, open up chances for  scholarships, funds with many more and stay informed about what rolls around all the universities in the country.." />  
            </Helmet>
            <div><Toaster/></div>
            <div className="flex backdrop-blur-sm bg-black/30 cont bg-cover bg-no-repeat bg-blend-multiply bg-center justify-center" style={{minHeight: '100vh', backgroundImage: `url(${heroimage})`}}>
                <Navbar /> 
                 
                <div className="py-7 px-5 content md:mt-20 mx-2 my-auto md:py-10 px-4 rounded-xl font-bold text-center">
                    <h1 className="primary text-2xl  lg:text-5xl ">Create Account</h1>
                    <form onSubmit={handleSubmit} className="mx-3 font-semibold">
                        <input type="text" required value={username} onChange={(e) => setUsername(e.target.value)} className="rounded mx-auto mt-4  py-4 w-full md:w-2/3 px-3 bg-black-000  focus:outline-none w-2/3" placeholder="Enter Username" />
                        <input type="email" required value={email} onChange={(e) => setEmail(e.target.value)} className="rounded mx-auto mt-4  py-4 w-full md:w-2/3 px-3  focus:outline-none w-2/3" placeholder="Email Address" />
                        <input type="password" required value={password} onChange={(e) => setPassword(e.target.value)} className="rounded mx-auto mt-4 py-4  w-full md:w-2/3 px-3 focus:outline-none w-2/3" placeholder="Enter Password" />
                        <input type="password" required value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="rounded mx-auto mt-4 py-4  w-full md:w-2/3 px-3 focus:outline-none w-2/3" placeholder="Confirm Password" />
                        <div className="my-5 mx-auto md:text-xl font-normal md:w-2/3 text-start"> 
                            <div className="text-white">
                                <GoogleLogin 
                                    onSuccess={responseMessage}
                                    onError={errorMessage}
                                  />
                            </div>
                        </div>
                        <div className="my-5 mx-auto md:text-xl font-normal md:w-2/3 text-start"> 
                            <div className="text-white">
                                <Link to='/login' className="links">Already have an account?</Link>
                                
                            </div>
                        </div>
                        <button type="submit" disabled={isLoading} className="button  rounded-xl block w-1/2 mx-auto px-2 py-2" >
                        {isLoading ?
                        <l-waveform
                        size="25"
                        stroke="3.5"
                        speed="1" 
                        color="white" 
                        ></l-waveform> : 'Create Account' }
                        </button>
                    </form> 
                </div> 
            </div>
            <Footer className="mt-aut0" />           
        </div>
     );
}
 
export default SignUp;